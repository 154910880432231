export default function AddressResultsItem({
  item,
  index,
  highlightedIndex,
  getItemProps,
  selectItem,
}) {
  return (
    <li className="address-finder__result-item">
      <a
        href={item.location}
        className={`address-finder__result-item-anchor ${
          highlightedIndex === index &&
          'address-finder__result-item-anchor--selected'
        }`}
        {...getItemProps({
          key: item.id,
          index,
          item,
        })}
        onClick={(e) => {
          e.preventDefault();
          selectItem(item);
        }}
      >
        {item.label}
      </a>
    </li>
  );
}
