import { Component } from 'react';
import Downshift from 'downshift';
import axios from 'axios';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import AddressResults from './components/AddressResults/AddressResults';
import Icon from '../../components/Icon/Icon';
import './index.scss';

type Selection = {
  location?: string;
  label?: string;
};

type AppProps = {};

type AppState = {
  page: number;
  hasMore: boolean;
  fetching: boolean;
  error?: string;
  addresses?: any[];
};

export default class App extends Component<AppProps, AppState> {
  static pageSize = 5;

  inputValue = '';

  debouncedHandleInput = debounce(
    (input: string) => this.processInput(input),
    500,
  );

  constructor(props: AppProps) {
    super(props);

    this.state = {
      page: 1,
      hasMore: false,
      fetching: false,
      error: '',
      addresses: null,
    };
  }

  async getAddresses() {
    const { page, addresses } = this.state;

    try {
      const responseAddress = await this.requestAddresses();

      if (page > 1) {
        this.setState({
          addresses: addresses && addresses.concat(responseAddress),
        });
      } else {
        this.setState({
          addresses: responseAddress,
        });
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        this.setState({
          error: 'There was an error in your request, please try again.',
        });
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        this.setState({
          error: 'The request couldn’t be completed, please try again later.',
        });
      } else {
        this.setState({
          error: 'There was an error creating the request, please try again.',
        });
      }

      this.setState({
        hasMore: false,
      });
    } finally {
      this.setState({
        fetching: false,
      });
    }
  }

  handleInput = (inputValue: string) => {
    if (inputValue.length > 4) {
      this.debouncedHandleInput(inputValue);
    } else {
      this.setState({
        addresses: null,
        hasMore: false,
      }); // reset
    }
  };

  handleShowMoreButton = () => {
    const { page } = this.state;

    this.setState(
      {
        page: page + 1,
      },
      () => this.getAddresses(),
    );
  };

  processInput = (inputValue: string) => {
    this.inputValue = inputValue;

    this.setState(
      {
        page: 1,
      },
      () => this.getAddresses(),
    ); // reset
  };

  redirect = (selection: Selection) => {
    this.debouncedHandleInput.cancel();
    this.setState({
      fetching: true,
    });

    // Putting delay here to give time for users to see that the address
    // they chose has been selected and would display on the input before
    // redirecting them to the Plan Picker.
    setTimeout(() => {
      window.location.href = selection ? selection.location : '';
    }, 2000);
  };

  async requestAddresses() {
    const { page } = this.state;

    this.setState({
      hasMore: false,
      fetching: true,
      error: '',
    }); // reset

    const { data } = await axios.get('/addresses/api/v1/completion/', {
      params: {
        term: this.inputValue,
        page,
        per_page: App.pageSize,
      },
    });

    const { results, suggestions } = data.pop();

    const addresses = results.concat(suggestions);

    // When show_more is set to true, the result will be per_page + 1
    // e.g. if per_page is set to 5 and show_more is set to true the result will have
    // an additional row which indicates that there are more pages
    // See show_more http://addressright.co.nz/addressright/fuzzycomplete-request
    const filteredAddresses = filter(addresses, (address) => {
      if (address.id !== 'MORE') return true;

      if (address.id === 'MORE' && address.value !== 0) {
        this.setState({
          hasMore: true,
        });
      }

      return false;
    });

    // Hence, we're making sure the result will only be what's set on pageSize.
    return filteredAddresses.slice(0, App.pageSize);
  }

  render() {
    const { addresses, hasMore, fetching, error } = this.state;

    return (
      <Downshift
        onInputValueChange={this.handleInput}
        onChange={this.redirect}
        itemToString={(item) => (item ? item.label : '')}
      >
        {({
          getLabelProps,
          getInputProps,
          getMenuProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          selectItem,
        }) => {
          const input = getInputProps();
          const shouldOpen =
            (isOpen && input.value.toString().length > 4) || false;
          return (
            <div
              className={`address-finder ${
                shouldOpen && 'address-finder--has-results'
              }`}
            >
              <div
                className={`address-finder__form ${
                  shouldOpen && 'address-finder__form--has-results'
                }`}
              >
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
                <label className="u-relative" {...getLabelProps()}>
                  <span className="u-accessible">Your Address</span>
                  <input
                    className="input input--rounded u-border-color-green input--icon"
                    placeholder="Enter your street address"
                    {...input}
                  />
                  {fetching ? (
                    <span className="address-finder__icon">
                      <Icon
                        title="Searching..."
                        type="icon-loading"
                        className="i--medium"
                      />
                    </span>
                  ) : (
                    <span className="address-finder__icon">
                      <Icon
                        title="Search"
                        type="icon-search"
                        className="i--medium"
                      />
                    </span>
                  )}
                </label>
              </div>

              <div
                className={`address-finder__result ${
                  shouldOpen
                    ? 'address-finder__result--show'
                    : 'address-finder__result--hide'
                }`}
              >
                <div className="address-finder__result-items">
                  {!error ? (
                    <AddressResults
                      addresses={addresses}
                      getItemProps={getItemProps}
                      getMenuProps={getMenuProps}
                      highlightedIndex={highlightedIndex}
                      selectItem={selectItem}
                    />
                  ) : (
                    <p>{error}</p>
                  )}
                </div>
                <button
                  type="button"
                  className={`address-finder__show-more link link--underline ${
                    hasMore
                      ? 'address-finder__show-more--show'
                      : 'address-finder__show-more--hide'
                  }`}
                  onClick={this.handleShowMoreButton}
                >
                  Show more results
                </button>
                <hr />
                <p>
                  If you’re having trouble finding your address,{' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="/contact-us/"
                    className="link link--underline link--underline-white"
                  >
                    get in touch
                  </a>{' '}
                  and we’ll look into getting you up and running.
                </p>
              </div>
            </div>
          );
        }}
      </Downshift>
    );
  }
}
