import { createRoot } from 'react-dom/client';
import App from './App';

const SELECTOR = '[data-address-finder]';

window.addEventListener('DOMContentLoaded', () => {
  const wrapper = document.querySelector(SELECTOR);

  if (wrapper) {
    const root = createRoot(wrapper!);
    root.render(<App />);
  } else {
    console.info(
      'No address finder on the page that matches selector: ',
      SELECTOR,
    );
  }
});
