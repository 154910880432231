import queryString from 'query-string';
import AddressResultsItem from '../AddressResultsItem/AddressResultsItem';

type AddressResultsProps = {
  addresses: any[]; // TODO: Refine type
  getItemProps: Function;
  getMenuProps: Function;
  highlightedIndex: number;
  selectItem: Function;
};

export default function AddressResults({
  addresses,
  getItemProps,
  getMenuProps,
  highlightedIndex,
  selectItem,
}: AddressResultsProps) {
  if (!addresses) return null;

  if (addresses.length === 0) {
    return (
      <p>
        Sorry we couldn’t find your address. You can try again using the format
        1 SOMEWHERE STREET, SUBURB
      </p>
    );
  }

  return (
    <ul className="list-unstyled" {...getMenuProps()}>
      {addresses.map((address, index) => {
        const { search } = window.location;
        let params = queryString.parse(search);
        params = {
          ...params,
          addressId: address.id,
        };
        const location = `/plan-picker?${queryString.stringify(params)}`;
        const item = {
          ...address,
          location,
        };

        return (
          <AddressResultsItem
            key={address.id}
            item={item}
            index={index}
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
            selectItem={selectItem}
          />
        );
      })}
    </ul>
  );
}
